import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'components/common'
import Submission from '../Submission'
import { Wrapper, Flex, Item, FlexHeader } from './styles'
import hireIllustration from 'assets/hire.svg'

export default () => {
  const {
    intro: {
      pageTitle,
      paragraphs,
      button,
      question,
      illustration: { icon },
    },
  } = useStaticQuery(graphql`
    {
      intro: introYaml {
        button
        pageTitle
        paragraphs
        question
        illustration {
          icon: publicURL
        }
      }
    }
  `)

  return (
    <Wrapper as={Container}>
      <Flex>
        <Item>
          <FlexHeader>
            <h1>{pageTitle}</h1>
          </FlexHeader>
          {paragraphs.map((item, i) => (
            <p key={i} dangerouslySetInnerHTML={{ __html: item }} />
          ))}
          <Submission button={button} question={question} />
        </Item>
        <Item>
          <img src={icon} alt={pageTitle} />
        </Item>
      </Flex>
    </Wrapper>
  )
}
