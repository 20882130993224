import React from 'react'
import { useMixpanel } from 'gatsby-plugin-mixpanel'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Container, Accordion } from 'components/common'
import { Wrapper, Section, Divider } from './styles'

export default () => {
  const mixpanel = useMixpanel()
  const {
    faq: { edges },
  } = useStaticQuery(graphql`
    query {
      faq: allFaqYaml {
        edges {
          node {
            id
            question
            answer
          }
        }
      }
    }
  `)

  return (
    <>
      <Divider />
      <Wrapper as={Container} id="faq">
        <Section>
          <h1>FAQ</h1>
          {edges.map(({ node: { id, question, answer } }) => (
            <Accordion key={id} title={question} description={answer} />
          ))}
        </Section>
        <Link
          to="/contact/"
          onClick={() => {
            mixpanel.track('Contact', {
              type: 'click',
            })
          }}
        >
          Still have some questions?
        </Link>
      </Wrapper>
    </>
  )
}
