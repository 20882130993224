import React from 'react'
import { ErrorMessage, FastField, Form, Formik } from 'formik'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Recaptcha from 'react-google-recaptcha'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
import { useMixpanel } from 'gatsby-plugin-mixpanel'
import { Button, Input } from 'components/common'
import { recaptcha_key } from 'data/config'
import { Error, InputField, Learn, Flex, Item } from './styles'
import 'react-phone-input-2/dist/style.css'

export default ({ question, button }) => {
  const mixpanel = useMixpanel()
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        FNAME: '',
        LNAME: '',
        EMAIL: '',
        recaptcha: '',
        PHONE: '',
        msg: null,
        success: null,
      }}
      validationSchema={Yup.object().shape({
        FNAME: Yup.string().required('Please enter your first name'),
        LNAME: Yup.string().required('Please enter your first name'),
        EMAIL: Yup.string()
          .email('Please enter a valid email address')
          .required('Please enter your email address'),
        // recaptcha: Yup.string()
        //   .required('Robots are not welcome yet!')
        //   .nullable(),
      })}
      onSubmit={async (
        { FNAME, LNAME, EMAIL, PHONE, recaptcha },
        { setSubmitting, setValues, setErrors }
      ) => {
        try {
          const res = await addToMailchimp(EMAIL, {
            FNAME,
            LNAME,
            PHONE,
            pathname: document.location.pathname,
          })
          if (res.result === 'success') {
            setValues({
              status: 'success',
              msg: res.msg,
              EMAIL,
              FNAME,
              LNAME,
              recaptcha,
            })
            setSubmitting(false)
          } else {
            setValues({
              status: 'error',
              msg: res.msg,
              EMAIL,
              FNAME,
              LNAME,
              recaptcha,
            })
            setSubmitting(false)
          }
        } catch (err) {
          setErrors({ msg: 'Something went wrong', status: 'error' })
          setSubmitting(false)
        }
      }}
    >
      {({ setFieldValue, isSubmitting, touched, errors, values }) => (
        <Form style={{ paddingTop: 20 }}>
          {values.status !== 'success' && (
            <>
              <Flex>
                <Item>
                  <InputField>
                    <Input
                      id="FNAME"
                      aria-label="FNAME"
                      component="input"
                      as={FastField}
                      type="text"
                      name="FNAME"
                      placeholder="First name"
                      error={touched.FNAME && errors.FNAME}
                    />
                    <ErrorMessage component={Error} name="FNAME" />
                  </InputField>
                </Item>
                <Item>
                  <InputField>
                    <Input
                      id="LNAME"
                      aria-label="LNAME"
                      component="input"
                      as={FastField}
                      type="text"
                      name="LNAME"
                      placeholder="Last name"
                      error={touched.LNAME && errors.LNAME}
                    />
                    <ErrorMessage component={Error} name="LNAME" />
                  </InputField>
                </Item>
              </Flex>
              <Flex>
                <Item>
                  <InputField>
                    <FastField
                      as={PhoneInput}
                      name="PHONE"
                      onChange={e => setFieldValue('PHONE', e)}
                      defaultCountry={'ma'}
                    />
                  </InputField>
                </Item>
                <Item>
                  <InputField>
                    <Input
                      id="EMAIL"
                      aria-label="EMAIL"
                      component="input"
                      as={FastField}
                      type="email"
                      name="EMAIL"
                      placeholder="Enter your email here"
                      error={touched.EMAIL && errors.EMAIL}
                    />
                    <ErrorMessage component={Error} name="EMAIL" />
                  </InputField>
                </Item>
              </Flex>
              <InputField>
                <FastField
                  component={Recaptcha}
                  sitekey={recaptcha_key}
                  name="recaptcha"
                  onChange={value => setFieldValue('recaptcha', value)}
                />
                <ErrorMessage component={Error} name="recaptcha" />
              </InputField>
            </>
          )}
          {values.status === 'success' && (
            <InputField green>
              Thanks for your interest! We will notify you once we're ready to
              launch our first workshop!{' '}
              <span role="img" aria-label="successfully subscribed">
                🎉
              </span>
            </InputField>
          )}
          {values.status === 'error' && (
            <Error dangerouslySetInnerHTML={{ __html: values.msg }} />
          )}
          {values.status !== 'success' && (
            <Button secondary type="submit" disabled={isSubmitting}>
              {button}
            </Button>
          )}
          <Learn
            as={AnchorLink}
            onClick={() => {
              mixpanel.track('Learn more', {
                type: 'click',
              })
            }}
            href="#faq"
          >
            {question}
          </Learn>
        </Form>
      )}
    </Formik>
  )
}
