import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 2rem 0 8rem 0;

  a {
    color: #1264a3;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid #1264a3;
    word-break: break-word;
  }
`

export const Section = styled.div`
  padding: 4rem 0;
`

export const Divider = styled.div`
  max-width: 600px;
  height: 1px;
  width: 100%;
  background: #d8d8d8;
  margin: 0 auto;
`
