import React from 'react'
import { Layout, SEO } from 'components/common'
import Intro from 'components/landing/Intro'
import FAQ from 'components/landing/FAQ'

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <FAQ />
  </Layout>
)
