import styled from 'styled-components'

export const Error = styled.span`
  color: #ff4136;
`

export const InputField = styled.div`
  position: relative;
  margin-bottom: 1rem;

  ${({ green }) =>
    green &&
    `
    color: #8BC34A;
    font-size: 16pt;
  `}
`

export const Learn = styled.a`
  color: #1264a3;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #1264a3;
  word-break: break-word;
  margin-left: 1rem;
`

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Item = styled.div`
  width: 100%;
  max-width: 48%;

  @media (max-width: 680px) {
    max-width: 100%;

    &:first-child {
      margin-bottom: 1rem;
    }
  }
`
